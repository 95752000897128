import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"

const browser = typeof window !== "undefined" && window;

class FourOhFour extends React.Component {
  render() {
    return browser && (
      <Layout location={this.props.location}>
        <Container>
          <h1>页面没有找到</h1>
        </Container>
      </Layout>
    )
  }
}

export default FourOhFour
